<template>
  <div class="pa-2">
    <v-card>
      <v-card-text :style="{ height: contentHeight + 'px' }">
        <a-table-f-api
          ref="table"
          :api="getUrl"
          :model="model"
          :useQuery="true"
          :defaults="getConfigView(viewName).index.defaults"
          @click="onClickRow($event)"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import genModel from "@/share/components/libs/genModel";
import table from "./table";

export default {
  mixins: [table, genModel],
  data() {
    return {};
  },
  computed: {
    getUrl() {
      return this.$root.api[this.$route.params.api];
    },
    model() {
      this.$root.title = this.getConfigView(this.viewName).name;
      return this.modelIndex;
    },
  },
  methods: {
    async onClickRow(e) {
      await this.$axios.p(this.$root.api.cardManagerEvent, {
        id: e.row.id,
        status: 1,
      });
      this.$router.push({
        name: "get-card-id",
        params: { id: e.row.caller_item_id },
      });
    },
  },
};
</script>